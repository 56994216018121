@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/ColorFigma.less';

.emailContainer {
  padding: 55px 55px 40px 55px;
  border-radius: @border-radius-sm;
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.05);
}

.contentContainer {
  max-width: 576px;
}

.thankText {
  font-size: 34px;
  line-height: 40px;
  font-weight: 500;
  color: @text-primary;
}

.textGroup {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.normalText {
  font-size: 14px;
  line-height: 24px;
  color: @text-secondary;
  font-weight: 400;
}

.digitalRfqContent {
  max-width: 576px;
  border-radius: 10px;
  padding: 24px;
  background: #f2f3f5;
}

.quoteTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: @gray-10;
}

.quoteText {
  font-size: 14px;
  line-height: 24px;
  color: @gray-10;
}

.quoteTextBold {
  font-size: 14px;
  line-height: 24px;
  color: @gray-10;
  font-weight: 500;
}

.infoText {
  font-size: 12px;
  line-height: 20px;
  color: @text-secondary;
}

.infoTextEnd {
  font-size: 12px;
  line-height: 20px;
  font-family: 'Inter';
}

@primary-color: #2F54EB;