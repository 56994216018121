@import './Color.less';
@import './ColorFigma.less';
@import './CircularTypography.less';
@import './Breakpoint.less';
@import './Spacing';
@import './Size';
@import './Table';
@import './DigitalRfq';
@import './SupportClasses.less';

@import '../pages/auth/Auth.less';
@import '../pages/company/Company.less';
@import '../components/form/Form.less';
@import '../components/icons/Statuses.less';
@import '../components/layout/Layout.less';
@import '../components/navigate/Breadcrumb.less';
@import '../components/steps/Steps.less';
@import '../components/timeline/Timeline.less';
@import '../components/splash/Splash.less';
@import '../pages/expert/Expert.less';
@import '../pages/esgCredits/EsgCredits.less';
@import '../pages/supplyChainRisk/SupplyChainRisk.less';
@import '../pages/verifiedCertification/Certificate.less';
@import '../components/layout/FormPageLayout.less';
@import '../pages/maps/Map.less';
@import '../components/header/SimpleHeader.less';
@import '../pages/search/Search.less';
@import '../pages/testing/TestLab.less';
@import '../pages/project/MarketsPlayground.less';

@import './Print.less';
@import './CheckboxCard.less';
@import './FullScreenSteps.less';

html,
body {
  scroll-behavior: smooth;
}

body {
  .body();
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: @body-background-color;
  color: @text-primary;
  line-height: 1.5715;

  &.hide-intercom {
    .intercom-lightweight-app,
    #intercom-container {
      display: none !important;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin-top: 0;
  margin-bottom: 24px;
  font-family: @font-family-secondary;
  font-weight: 400;

  &.small-label {
    font-size: 12px;
    font-weight: 300;
  }

  &.italic-label {
    font-style: italic;
  }
}

iframe {
  margin: 0;
  border: 0;
}

.dark-background {
  .light-text {
    color: #ccd5f6;

    /* Base/Normal */
    font-family: @font-family-secondary;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

.error-not-found {
  text-align: center;
  margin: 80px auto;
}

.navigation-return {
  .anticon {
    color: #000;
    margin-right: 15px;
  }
}

.app-content {
  flex: 1 0 auto;
}

.logo {
  display: block;
  line-height: 0;

  svg {
    width: 130px;
    height: auto;
  }
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;

  &.dev {
    .simple-header-container .logo::after {
      content: '(DEV)';
      display: inline-block;
      color: white;
      font-weight: bold;
      font-size: 12px;

      @media (min-width: @md-bp) {
        font-size: 20px;
      }
    }

    .simple-header-container.scrolling .logo::after {
      color: @gray-10;
    }
  }
}

.general-warning {
  width: 100%;
  text-align: center;
  padding: 20px 20px;
  background: #f9e8e8;
  border: 1px solid #eed8d8;
  margin-bottom: 24px;
}

.light-text {
  color: @gray-7;
}

.small {
  font-size: 0.63em;
  line-height: 0.63em;
}

.footer {
  flex-shrink: 0;
  width: 100%;
}

button {
  cursor: pointer;

  &.bare {
    border: 0;
    background: none;
    padding: 0;
    color: inherit;
    font-family: inherit;
  }
}

.nowrap {
  white-space: nowrap;
}

.responsive-table {
  @media (max-width: @sm-bp) {
    table {
      border-width: 4px 1px 0;
      border-color: #f0f0f0;
      border-style: solid;

      thead {
        display: none;
      }

      tr {
        display: block;
        border-bottom-width: 1px;

        &:nth-child(2n) {
          background: #fafafa;
        }

        td {
          display: block;
          background: none;

          &:not(:last-child) {
            border: 0;
            padding-bottom: 8px;
          }

          > *::before {
            display: block;
            content: attr(data-title);
            font-size: 12px;
            font-weight: 500;
            color: @gray-7;
          }
        }
      }
    }
  }
}

#circular-cookie-root {
  .ant-notification {
    bottom: 0px !important;
    right: 0px !important;
    left: 0px !important;
    margin: 0;
    width: 100%;

    .ant-notification-notice {
      margin: 0;
      width: 100%;
      max-width: 100vw;

      .ant-notification-notice-description {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        flex-direction: row;

        .cookie-policy-body {
          flex: 1 0 350px;
        }

        .ant-space {
          button {
            width: 100%;
          }
        }
      }
    }
  }
}

.query-status {
  .ant-alert {
    margin: 0 32px;
  }
}

.app-error-fallback {
  padding: 50px;
}

a.admin-console-link.icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  z-index: 1000;
  text-align: center;

  @media print {
    display: none;
  }
}

.sesame-invite {
  @media print {
    display: none;
  }
}

.goodness-rating {
  text-transform: uppercase;
  font-family: @font-family-primary;
  display: inline-block;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.7px;

  &.pill {
    padding: 8px 12px;
  }

  span {
    margin-right: 6px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &.dark.pill {
    background-color: @dark-background-panel;
    border-radius: 20px;
  }

  &.light.pill {
    background: @blue-2;
    border-radius: 20px;
  }
}

.rainbow-progress {
  border-radius: 4px;
  height: 10px;
  width: 100%;
  background: linear-gradient(
    270deg,
    #51ce92 -2.99%,
    #fbc12d 55.99%,
    #eb2f96 99.58%
  );

  div {
    height: 10px;
    background-color: @dark-background-panel;
    border-radius: 0 4px 4px 0;
    float: right;
  }
}

.bar-progress {
  &--bars {
    display: flex;
    flex-direction: row;
    width: 100%;

    .box {
      height: 32px;

      &.empty {
        background-color: @gray-4;
      }

      &.amber {
        background-color: @gold-6;
      }

      &.green {
        background-color: @green-6;
      }

      &.red {
        background-color: @red-6;
      }

      &.normal {
        background-color: @blue-6;
      }

      &.high {
        background-color: @blue-7;
      }

      &.medium {
        background-color: @blue-5;
      }

      &.hatch {
        background: repeating-linear-gradient(
          45deg,
          #cae4f7,
          #cae4f7 4px,
          white 4px,
          white 8px
        );
      }
    }
  }

  &--labels {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 166.667% */
  }
}

.block-progress {
  &--blocks {
    display: flex;
    flex-direction: row;
    gap: 2px;
    width: 100%;

    .box {
      flex: 1 0;
      height: 32px;

      &.empty {
        background-color: @gray-4;
      }

      &.amber {
        background-color: @gold-6;
      }

      &.green {
        background-color: @green-6;
      }

      &.red {
        background-color: @red-6;
      }

      &.normal {
        background-color: @blue-6;
      }
    }
  }
}

.progress {
  border-radius: 4px;
  height: 10px;
  width: 100%;

  div {
    height: 10px;
    background: repeating-linear-gradient(
      45deg,
      #aaa,
      #aaa 3px,
      transparent 3px,
      transparent 6px
    );
    mix-blend-mode: multiply;
    border-radius: 0 4px 4px 0;
    float: right;
  }
}

.material-table-empty {
  padding: 100px 16px;

  .ant-empty-image {
    height: auto;

    img {
      width: 217px;
    }
  }
}

// Global Ant style overrides
.ant- {
  &alert {
    border-radius: 8px;
    padding: 20px;

    &.ant-alert-info {
      background: #f7f9ff;
      border-color: #d7dfff;
    }

    .ant-alert-message {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 16px;

      .circular-icon {
        vertical-align: -0.4em;
        margin-right: 8px;
      }

      + .ant-alert-description {
        color: #64748d;
      }
    }

    .ant-alert-icon {
      .circular-icon {
        margin-right: 16px;
        height: auto;
      }
    }

    &.vertical {
      flex-wrap: wrap;
      > * {
        flex-basis: 100%;
      }
    }
  }
  &notification {
    .ant-notification-notice {
      .ant-notification-notice-message {
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
      }
    }
  }

  &card.ant-card-bordered {
    border-color: @cool-border-color;
  }

  // these apply to the TDS modal, so making them global unless
  // designs require otherwise for future modals

  &modal {
    top: 50px;

    .ant-modal-content {
      border-radius: 4px;
      max-height: calc(100vh - 100px);
      overflow: scroll;

      .ant-modal-header {
        border-radius: 16px 16px 0 0;
      }
    }
  }

  &input,
  &select.ant-select-sm .ant-select-selector,
  &select .ant-select-selector {
    border-radius: 6px;

    // &:not(.ant-input-sm):not(.ant-input-lg):not(.ant-select-sm):not(.ant-select-lg) {
    //   height: 32px;
    // }
  }

  &input:disabled {
    background: @gray-2;
    color: rgba(0, 0, 0, 0.7);
  }

  &input-group {
    height: 100%;

    > input {
      height: 100%;
      padding-left: 11px;
      padding-right: 11px;
    }

    .ant-select {
      .ant-select-selector {
        padding: 0;
      }

      .ant-select-arrow {
        right: 7px;
      }
    }
  }

  &form-item {
    &-label label {
      color: @gray-10;
      font-family: @font-family-secondary;
      font-size: 14px;
      font-style: normal;
      line-height: 22px;
      font-weight: 500;
    }

    &.small-label label {
      font-size: 12px;
      font-weight: 300;
    }

    &.large-label .ant-form-item-label label {
      font-family: 'Roboto', sans-serif;
      font-size: 24px;
      font-weight: 400;
    }

    &.other-option {
      max-width: 300px;
      margin-top: -25px;

      .ant-form-item-label {
        padding-bottom: 0;
        padding-top: 4px;

        > label {
          font-size: 0.9em;

          &.ant-form-item-required::before {
            display: none;
          }
        }
      }
    }
  }

  &typography {
    &.ant-typography-success {
      color: #447d61;
    }

    &.ant-typography-danger {
      color: #cf1322;
    }

    &.ant-typography-primary {
      color: @link-blue;
    }

    &.ant-typography-secondary {
      color: @text-secondary;
      opacity: 1;
      font-weight: 400;
    }
  }

  &space {
    width: 100%;
  }

  &upload-wrapper {
    .ant-upload-list .ant-upload-list-item {
      border: 1px solid @gray-5;
      padding: 4px 8px;
      border-radius: 6px;
      // For some reason some ant list items render with a fixed "height: 0"
      height: auto !important;

      a {
        color: @text-primary;
        font-weight: 400;
      }

      &-done {
        .ant-upload-list-item-actions .ant-upload-list-item-action {
          opacity: 1;
        }
      }
    }
  }

  &btn {
    border-radius: 4px;
    font-weight: 600;
    height: auto;

    &:not(.ant-btn-sm) {
      padding: 5px 10px;
    }

    &.ant-btn-primary:not(.ant-btn-dangerous) {
      // background: @link-blue;
      &:disabled {
        background: @link-blue;
        color: white;
        opacity: 0.5;
      }
    }

    &.ant-btn-lg {
      padding: 8px 16px;
    }

    &.ant-btn-link {
      color: #2f54eb;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &dropdown {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        // Padding on these causes click events to not work when the menu item is a link or button
        padding: 0;

        .ant-dropdown-menu-title-content > * {
          padding: 6px 12px;
          display: block;
        }
      }
    }
    .ant-table-filter-dropdown {
      .ant-dropdown-menu
        .ant-dropdown-menu-item
        .ant-dropdown-menu-title-content {
        padding: 4px 6px;
        display: flex;
        align-items: center;
        gap: 4px;
        > * {
          padding: 0;
        }
        .ant-checkbox-wrapper::after {
          display: none;
        }
      }
    }
  }

  &checkbox {
    + span {
      align-self: center;
    }
  }

  &popover {
    max-width: 415px;
  }

  &tabs {
    &.bordered-tabs {
      > .ant-tabs-nav {
        .ant-tabs-nav-wrap {
          width: 100%;

          .ant-tabs-nav-list {
            width: 100%;
            display: grid;
            grid-auto-columns: minmax(0, 1fr);
            grid-auto-flow: column;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid #dfe3ed;

            .ant-tabs-tab {
              padding: 16px 20px;
              margin: 0;

              &:not(:first-child) {
                border-left: 1px solid #dfe3ed;
              }

              &:first-child {
                border-radius: 8px 0 0 8px;
              }

              &:nth-last-child(2) {
                border-radius: 0 8px 8px 0;
              }
            }

            .ant-tabs-ink-bar {
              height: 4px;
            }
          }
        }
      }
    }
  }

  &tag {
    border: 0;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    vertical-align: middle;

    &.ant-tag-blue {
      color: @link-blue;
      background: @blue-1;
    }
  }

  &slider {
    .ant-slider-track {
      background-color: @primary-color;
    }

    .ant-slider-handle::after {
      box-shadow: 0 0 0 2px @primary-color;
    }
  }

  &row {
    &.individually-scrolling-cols {
      height: 100vh;
      > .ant-col {
        max-height: 100%;
        overflow-y: scroll;
      }
    }
  }
}

#documents_extra {
  margin-top: 4px;
}

.box-panel {
  border-radius: @border-radius-lg;
  border: 1px solid @cool-border-color;
  box-sizing: border-box;

  /* New Ant (EXP) */
  box-shadow: 0 2px 0 0 #f6f7f9;

  &.dark {
    background: @dark-background-panel;
  }

  &.solid {
    border: 0;
    background: #f5f5f5;
    padding: 12px;
    border-radius: 8px !important;
  }

  &--header {
    border-bottom: 1px solid @gray-5;
    padding: 12px 16px;
  }
}

.ant-row-stretch > .ant-col > .box-panel {
  height: 100%;
}

.status-pill {
  display: inline-block;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 500px;
  background-color: #f2f2f2;
  font-weight: 400;

  &.done {
    background-color: #eef7ea;
    color: @green-3;
  }

  &.error {
    background-color: @red-10;
    color: @red-4;
  }
}

.loading-state-element {
  transition: opacity 0.2s;

  &.is-loading {
    opacity: 0.5;
    cursor: wait;
  }
}

.disableable-content {
  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;

    > * {
      pointer-events: none;
    }
  }
}

.select-with-decorators {
  .select-decorator-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .select-decorator-label {
      margin: 0 4px;
    }

    .select-decorator-count {
      display: inline-block;
      height: 18px;
      line-height: 18px;
      text-align: center;
      min-width: 18px;
      color: #fff;
      font-size: 12px;
      background-color: @Colors-Brand_Tokens-Primary-colorPrimary;
      border-radius: 50%;
      padding: 0 2px;
    }
  }
}

.circular-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: -0.2em; // this is copied from antd icon styling
  height: 19px;
}

.react-markdown {
  p:last-child {
    margin-bottom: 0;
  }
}

@primary-color: #2F54EB;