@import (reference) '/src/styles/CircularTypography.less';
@import (reference) '/src/styles/Color.less';

.project-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 46px 30px 30px 30px;
  box-sizing: border-box;

  .project-table {
    margin-top: 24px;

    .ant-table-row {
      cursor: pointer;
      &:hover {
        .ant-table-cell {
          background: @gray-2;
        }
      }
    }

    .ant-tag {
      margin-inline-end: 4px;
      background-color: @blue-1;
    }
  }
}

@primary-color: #2F54EB;