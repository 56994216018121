@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/ColorFigma.less';

.manageButton {
  width: 100%;
  height: 50px;
}

.carouselDots {
  :global(.slick-active) {
    button {
      background-color: @primary-color !important;
    }
  }

  li {
    button {
      background-color: @primary-color !important;
    }
  }
}

.textSpot {
  color: @color-gray-9;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
}

.statusBadged {
  padding: 8px @size-sm;
  border-radius: 18px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  text-transform: capitalize;
  background-color: #f0f3ff;
}

.statusOnboarded {
  color: #2f54eb;
}

.statusProcure {
  color: #262626;
}

.statusManage {
  color: @gray-10;
}

.inputVolume {
  width: 100%;
  height: 40px;
  border-radius: @border-radius;
}

@primary-color: #2F54EB;