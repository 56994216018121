.iconSize {
  svg {
    width: 15px;
    height: 15px;
    line-height: 20px;
  }
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.iconText {
  font-size: 12px;
}

@primary-color: #2F54EB;