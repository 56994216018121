.whiteText {
  color: white !important;
}

.descriptionText {
  color: #c8c8c8;
}

.companyInput {
  border: none;
  background-color: #d2d6db4d !important;
  width: 364px;
  color: white;
}

@primary-color: #2F54EB;