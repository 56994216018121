@import (reference) '/src/styles/Color.less';

.signinContainer {
  background: @secondary-black;
  width: 100%;
  height: 100vh;
}

.logoContainer {
  margin-top: 35px;
}

@primary-color: #2F54EB;