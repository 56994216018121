@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/ColorFigma.less';

.badge {
  padding: 8px @size-sm;
  border-radius: 18px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  text-transform: capitalize;
}

.approved {
  background-color: @light-green;
  color: @gray-10;
}

.declined {
  color: @volcano-8;
  background-color: @volcano-10;
}

@primary-color: #2F54EB;