.informationText {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.materialTypeCard {
  border-radius: 4px;
  border: 1px solid #dfe3ed;
  background: #26262605;
  padding: 8px;
}

@primary-color: #2F54EB;