.leftContainer {
  background-color: #000;
  border-top: 1px solid #505050;
  display: flex;
  justify-content: center;
}

.leftContainerContent {
  margin-left: 0 !important;
  margin-top: 100px;
}

.leftLogoContainer {
  display: flex;
  justify-content: center;
}

.logoWrapper {
  width: 465px;
}

.setupButton {
  padding: 12px 17px;
  background: linear-gradient(104deg, #3a58ff 22.75%, #01a2ff 101.63%);
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  border-radius: 6px;
  border: 0;
}

@primary-color: #2F54EB;