@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Spacing';
@import (reference) '/src/styles/Size.less';

.searchInput {
  height: 32px;

  :global(.ant-input-group-addon:last-child) {
    border-end-end-radius: @border-radius-lg;
    border-start-end-radius: @border-radius-lg;
  }
}

.firstColumn {
  border-right: 1px solid @cool-border-color;
}

.foodGradeIcon {
  color: @polar-green-7;

  svg {
    font-size: @size !important;
  }
}

@primary-color: #2F54EB;