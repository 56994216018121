@import (reference) '/src/styles/CircularTypography.less';
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/ColorFigma.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/Spacing.less';

.markets-playground {
  &--header {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    border: 1px solid @cool-border-color;
    border-radius: 8px 8px 0 0;

    > div {
      padding: 12px;
      border-right: 1px solid @cool-border-color;
      &:last-child {
        border-right: none;
      }
    }
  }

  &--body {
    border: 1px solid @cool-border-color;
    border-radius: 0 0 8px 8px;
    > .ant-col {
      padding: 24px 20px;
      &:first-child {
        border-right: 1px solid @cool-border-color;
      }
    }
  }

  &--highlighted {
    .ant-alert .ant-alert-message {
      margin-bottom: 12px;
    }
  }
}
