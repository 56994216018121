.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.text-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}
