.inputOtp {
  input {
    height: 60px;
    background-color: #d2d6db;
    border: none;
    font-size: 24px;
    line-height: 24px;
  }

  div {
    width: 100%;
  }
}

.goBackLink {
  cursor: pointer;
  color: #ffffff;
  text-decoration: underline;
  margin-left: 8px;
}

@primary-color: #2F54EB;