@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/ColorFigma.less';

.projectManageContainer {
  padding: 14px @size-xl;
}

.card {
  padding: @size;
}

.cardWrapper {
  border-radius: @border-radius-lg;
  border: 1px solid @cool-border-color;
  box-shadow: 0px 2px 0px 0px #f6f7f9;
  height: 100%;
}

.supplierImage {
  border-radius: @border-radius;
}

.viewContractButton {
  font-weight: 500;
}

.searchShipments {
  :global(.ant-input-group .ant-input-group-addon:last-child) {
    border-start-end-radius: @size-xs;
    border-end-end-radius: @size-xs;
  }

  :global(.ant-input-affix-wrapper) {
    border-radius: @size-xs;
    height: 32px;
  }
}

.selectSort {
  min-width: 200px;
  height: 32px;
}

.shipmentColumn {
  border-right: 1px solid @cool-border-color;
}

.shipmentTable {
  :global(.ant-table-column-sorters) {
    justify-content: start;
  }
  :global(.ant-table-column-title) {
    flex: none;
  }
  :global(.ant-table-column-sorter) {
    display: flex !important;
  }
}

@primary-color: #2F54EB;